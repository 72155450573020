<template>
  <div class="flex items-center min-h-screen bg-gray-50 dark:bg-gray-900">
    <div class="container">
      <div class="lg:w-5/6 mx-auto my-10 bg-white py-5 rounded-md shadow-sm">

        <keep-alive>
            <individual-fund v-if="step == 1"></individual-fund>
            <individual-uploads v-if="step == 2"></individual-uploads>
            <campaign-story v-if="step == 3"></campaign-story>
        </keep-alive>


        <div class="lg:mx-40 my-10">
          <button @click="nextStep" v-if="step !=3" class="btn btn-primary btn-lg btn-block">
            Next
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IndividualFund from "./IndividualFund.vue";
import CampaignStory from "./CampaignStory.vue";
import IndividualUploads from "./IndividualUploads.vue";
export default {
  data() {
    return {
      step: 1,
      totalSteps: 2,
      errors: [],
    };
  },
  components: { IndividualFund, IndividualUploads, CampaignStory },
  methods: {
    nextStep() {
      this.step++;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>