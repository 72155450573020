<template>
  <div>
    <div class="pb-5">
      <div class="lg:flex justify-evenly">
        <img
          src="../../../assets/icons/Oonpay logo.png"
          class="img-fluid"
          height="80px"
          width="80px"
          alt=""
        />
        <h1
          class="
            my-3
            lg:text-3xl
            text-xl
            font-semibold
            text-gray-700 text-center
            dark:text-gray-200
          "
        >
          Individual Fundraising Add Cover <br />
          Photo/#FUNDTAG
        </h1>
      </div>
    </div>

    <div class="md:flex md:items-center pl-10 mb-6">
      <div class="md:w-3/3">
        <p class="lg:px-40 text-xl">
          Add a Cover photo or video Always look for a good high quality photo
          or video to supprt your funding raising campaign
        </p>
      </div>
    </div>

    <hr class="new4" />
    <div class="lg:px-20 flex items-center">
      <div class="w-1/4 text-xl font-bold text-right">Upload</div>
      <div class="lg:w-2/4 w-3/4 ml-2 lg:ml-4">
        <div class="custom-file">
          <input type="file" class="custom-file-input" id="customFile" />
          <label class="custom-file-label" for="customFile">Choose file</label>
        </div>
      </div>
    </div>

    <div class="youtubeSearch my-5">
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text" id="basic-addon3"
            >your youtube url</span
          >
        </div>
        <input
          type="text"
          class="form-control"
          id="basic-url"
          aria-describedby="basic-addon3"
        />
      </div>
    </div>

    <hr class="new4" />
    <div class="row flex justify-center">
      <div class="col-md-10 text-center space-y-10">
        <h4 class="font-bold">OONPAY SHAREABLE #FUNDTAG</h4>
        <div class="row">
          <div class="col-md-4">
            <p class="text-lg font-bold">
              Create Your Crowdfunding Shareable
              <span class="text-oonpaysecondary">#FUNDTAG</span>
            </p>
          </div>
          <div class="col-md-8">
            <div class="flex justify-center items-center">
              <div class="relative">
                <div class="absolute top-4 left-3">
                  <i
                    class="fa fa-search text-gray-400 z-20 hover:text-gray-500"
                  ></i>
                </div>
                <input
                  type="text"
                  class="
                    h-14
                    lg:w-96
                    pl-10
                    pr-20
                    rounded-lg
                    z-0
                    focus:shadow
                    focus:outline-none
                  "
                  placeholder="Search #FUNDTAG..."
                />
                <div class="absolute top-2 right-2">
                  <button
                    class="
                      h-10
                      w-20
                      text-white
                      rounded-lg
                      bg-oonpaysecondary
                      hover:bg-red-600
                    "
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="text-center">
      <small
        >Create a #fundtag & QR Code to help your audience or donor to find
        donate and share your #fundtag on the oonpay app and via printed media
        (i.e flyers, invitations and events )</small
      >
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    next() {
      this.$router.push("/campaign-story");
    },
  },
};
</script>

<style  scoped>
@media (min-width: 922px) {
  .youtubeSearch {
    padding: 0 200px;
  }
}

@media (max-width: 640px) {
  .youtubeSearch {
    padding: 0 20px;
  }
}
a:hover {
  text-decoration: none;
}

hr.new4 {
  border: 1px solid #ccc;
}

.btn-primary {
  background: #0c4369;
  border: #0c4369;
}
</style>