<template>
  <div>
    <div class="text-center flex flex-col items-center pb-5">
          <div class="flex space-x-4">
            <img
              src="../../../assets/icons/Oonpay logo.png"
              class="img-fluid"
              height="80px"
              width="80px"
              alt=""
            />
            <h1
              class="
                my-3
                text-3xl
                font-semibold
                text-gray-700
                dark:text-gray-200
              "
            >
              individual Fundraising
            </h1>
          </div>
        </div>

        <div class="md:flex md:items-center pl-10 mb-6">
          <div class="md:w-1/3">
            <label
              class="
                block
                text-gray-500
                font-bold
                md:text-right
                mb-1
                md:mb-0
                pr-4
              "
              for="inline-full-name"
            >
              Select country
            </label>
          </div>
          <div class="md:w-1/3">
            <div class="form-group">
              <select name="currency" class="form-control">
                <option value="usd">Ghana</option>
                <option value="usd">Nigeria</option>
              </select>
            </div>
          </div>
        </div>

        <div class="md:flex md:items-center pl-10 mb-6">
          <div class="md:w-1/3">
            <label
              class="
                block
                text-gray-500
                font-bold
                md:text-right
                mb-1
                md:mb-0
                pr-4
              "
              for="inline-full-name"
            >
              Select currency
            </label>
          </div>
          <div class="md:w-1/3">
            <div class="form-group">
              <select name="currency" class="form-control">
                <option value="usd">GHC</option>
                <option value="usd">Naira</option>
              </select>
            </div>
          </div>
        </div>

        <div class="md:flex md:items-center pl-10 mb-6">
          <div class="md:w-1/3">
            <label
              class="
                block
                text-gray-500
                font-bold
                md:text-right
                mb-1
                md:mb-0
                pr-4
              "
              for="inline-full-name"
            >
              Enter Campaign Amount
            </label>
          </div>
          <div class="md:w-1/3">
            <div class="form-group">
              <input type="number" placeholder="3000" class="form-control" />
            </div>
          </div>
        </div>

        <div class="md:flex md:items-center pl-10 mb-6">
          <div class="md:w-1/3">
            <label
              class="
                block
                text-gray-500
                font-bold
                md:text-right
                mb-1
                md:mb-0
                pr-4
              "
              for="inline-full-name"
            >
              Select Campaign Period
            </label>
          </div>
          <div class="md:w-1/3">
            <div class="form-group">
              <select name="category" class="form-control">
                <option>Select funding Duration</option>
                <option value="1">30 days</option>
                <option value="2">40</option>
                <option value="3">2 months</option>
              </select>
            </div>
          </div>
        </div>

        <div class="md:flex md:items-center pl-10 mb-6">
          <div class="md:w-1/3">
            <label
              class="
                block
                text-gray-500
                font-bold
                md:text-right
                mb-1
                md:mb-0
                pr-4
              "
              for="inline-full-name"
            >
              Select Start Date
            </label>
          </div>
          <div class="md:w-1/3">
            <div class="form-group">
              <input type="date" class="form-control" />
            </div>
          </div>
        </div>

        <div class="md:flex md:items-center pl-10 mb-6">
          <div class="md:w-3/3">
            <p class="px-5">
              All transaction fees include debit and credit charges, deducted
              from each donation at payouts
            </p>
            <p class="px-5">
              Enter Campaign Amount $3000 All transaction fees include debit and
              credit charges, deducted from each donation at payouts $ USD $ USD
              Select Currency Create Your Fund-Raising Campaign Goal To Recieve
              Money Select Your Country Select Your Country For USA Users to
              recieve money raised during your campaign, All person assigned to
              withdraw funds must have the following documents: A bank account
              and a mailing address in one of the 50 states A US social security
              number
            </p>
          </div>
        </div>

        <div class="md:flex md:items-center pl-10 mb-6">
          <div class="md:w-1/3">
            <label
              class="
                block
                text-gray-500
                font-bold
                md:text-right
                mb-1
                md:mb-0
                pr-4
              "
              for="inline-full-name"
            >
              Select Category
            </label>
          </div>
          <div class="md:w-1/3">
            <div class="form-group">
              <select name="category" class="form-control">
                <option>Choose Category</option>
                <option value="1">30 days</option>
                <option value="2">40</option>
                <option value="3">2 months</option>
              </select>
            </div>
          </div>
        </div>

        <div class="md:flex md:items-center pl-10 mb-6">
          <div class="md:w-1/3"></div>
          <div class="md:w-1/3">
            <div class="form-group">
              <input
                type="text"
                class="form-control"
                placeholder="city"
                name="city"
              />
            </div>
          </div>
        </div>

        <hr class="new4" />

        <h4 class="px-10">Organizers Info</h4>

        <div class="px-40">
          <div class="mt-1">
            <label class="inline-flex items-center">
              <input type="checkbox" class="form-checkbox h-6 w-6" />
              <span class="ml-3 text-lg">Add Validator</span>
            </label>
          </div>

          <div class="mt-1">
            <label class="inline-flex items-center">
              <input type="checkbox" class="form-checkbox h-6 w-6" />
              <span class="ml-3 text-lg">Add Group Organizer</span>
            </label>
          </div>
          
          Send invite to others to help you fundraise
        </div>
        <hr class="new4" />
        <div class="text-center">
          <h4>Organizers Info</h4>
          <p>
            For individuals organizers the oonpay crowdfunding platform fee per
            transaction is 2.9% plus $0.25 per donation by clicking on next you
            agree to oonpay terms and conditions and privacy policy
          </p>
        </div>
  </div>
</template>

<script>
export default {
  methods: {
    next() {
      this.$router.push("/individual-upload");
    },
  },
};
</script>

<style  scoped>
a:hover {
  text-decoration: none;
}

hr.new4 {
  border: 1px solid #ccc;
}

.btn-primary {
  background: #0c4369;
  border: #0c4369;
}
</style>