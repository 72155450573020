<template>
  <div>
    <div class="row">
      <div class="col-md-12 ml-2">
        <img
          src="../../../assets/icons/Oonpay logo.png"
          height="80px"
          width="80px"
          alt=""
        />
      </div>
      <div class="col-md-6 flex justify-center">
        <img src="https://dummyimage.com/250" alt="" />
      </div>
      <div class="col-md-6">
        <img src="https://dummyimage.com/250" alt="" />
      </div>
    </div>

    <hr class="new4" />

    <div class="row py-20">
      <div class="col-md-6 flex justify-center">
        <p>
          Shareable
          <span class="text-oonpaysecondary font-bold text-xl"
            >#FUNDTAG-QR-CODE</span
          >
        </p>
      </div>
      <div class="col-md-6">QR Code Here</div>
    </div>

    <hr class="new4" />
    <div class="row flex justify-center">
      <div class="col-md-10 text-center space-y-10">
        <h4 class="font-bold">Tell Your Fund-Raising Campaign Story Here</h4>
        <div class="row flex justify-center">
          <div class="col-md-8">
            <div class="form-group">
              <label class="flex justify-start text-sm" for="title">
                What is your fund-Raising Campaign Title ?
              </label>
              <input
                type="text"
                placeholder="Ex. Help Ade Pay For His Hospital Bill"
                class="form-control"
              />
            </div>
            <div class="form-group">
              <label class="flex justify-start text-sm" for="title">
                Explain why you are raising funds ?
              </label>
              <textarea
                class="form-control"
                placeholder="Ex. Hi, My name is James, I am raising funds for Ade hospital bills...."
                cols="30"
                rows="10"
              ></textarea>
            </div>
            <ul>
              <li class="flex justify-start text-sm">fundrasing tips</li>
              <li class="flex justify-start text-sm">
                tell your audience about the benefactor
              </li>
              <li class="flex justify-start text-sm">
                Describe what the funds will be used for
              </li>
              <li class="flex justify-start text-sm">
                Let the donors know how urgent its needed
              </li>
              <li class="flex justify-start text-sm">
                Share a grateful heart felt thank you for the help
              </li>
            </ul>

            <div class="row">
              <div class="col-md-6">
                <p
                  class="
                    px-3
                    bg-oonpayprimary
                    text-white
                    font-bold
                    text-lg
                    preview
                    py-1
                  "
                  data-toggle="modal"
                  data-target="#previewModalCenter"
                >
                  Preview Your Fund-Raising Campaign
                </p>
              </div>
              <div class="col-md-6">
                <p
                  @click="next"
                  class="
                    px-3
                    bg-oonpayprimary
                    text-white
                    font-bold
                    text-2xl
                    preview
                    py-3
                  "
                >
                  Create Campaign
                </p>
              </div>
            </div>
          </div>
        </div>

        <!-- Modal -->
        <div
          class="modal fade"
          id="previewModalCenter"
          tabindex="-1"
          role="dialog"
          aria-labelledby="previewModalCenterTitle"
          aria-hidden="true"
        >
          <div
            class="modal-dialog mw-100 w-75 modal-dialog-centered"
            role="document"
          >
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="previewModalLongTitle"></h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="row pb-5">
                  <div class="col-md-12">
                    <h4 class="text-left">Support Maya Recovery Today:</h4>
                  </div>
                  <div class="col-md-7">
                    <img src="https://via.placeholder.com/850x600" alt="" />

                    <nav class="my-4">
                      <div class="nav nav-tabs" id="nav-tab" role="tablist">
                        <a
                          class="nav-link active"
                          id="nav-home-tab"
                          data-toggle="tab"
                          href="#nav-home"
                          role="tab"
                          aria-controls="nav-home"
                          aria-selected="true"
                          >About</a
                        >
                        <a
                          class="nav-link"
                          id="nav-profile-tab"
                          data-toggle="tab"
                          href="#nav-profile"
                          role="tab"
                          aria-controls="nav-profile"
                          aria-selected="false"
                          >Comments</a
                        >
                        <a
                          class="nav-link"
                          id="nav-contact-tab"
                          data-toggle="tab"
                          href="#nav-contact"
                          role="tab"
                          aria-controls="nav-contact"
                          aria-selected="false"
                          >Updates</a
                        >
                      </div>
                    </nav>
                    <div class="tab-content" id="nav-tabContent">
                      <div
                        class="tab-pane fade show active"
                        id="nav-home"
                        role="tabpanel"
                        aria-labelledby="nav-home-tab"
                      >
                        Nulla est ullamco ut irure incididunt nulla Lorem Lorem
                        minim irure officia enim reprehenderit. Magna duis
                        labore cillum sint adipisicing exercitation ipsum.
                        Nostrud ut anim non exercitation velit laboris fugiat
                        cupidatat. Commodo esse dolore fugiat sint velit ullamco
                        magna consequat voluptate minim amet aliquip ipsum aute
                        laboris nisi. Labore labore veniam irure irure ipsum
                        pariatur mollit magna in cupidatat dolore magna irure
                        esse tempor ad mollit. Dolore commodo nulla minim amet
                        ipsum officia consectetur amet ullamco voluptate nisi
                        commodo ea sit eu.
                      </div>
                      <div
                        class="tab-pane fade"
                        id="nav-profile"
                        role="tabpanel"
                        aria-labelledby="nav-profile-tab"
                      >
                        Nulla est ullamco ut irure incididunt nulla Lorem Lorem
                        minim irure officia enim reprehenderit. Magna duis
                        labore cillum sint adipisicing exercitation ipsum.
                        Nostrud ut anim non exercitation velit laboris fugiat
                        cupidatat. Commodo esse dolore fugiat sint velit ullamco
                        magna consequat voluptate minim amet aliquip ipsum aute
                        laboris nisi. Labore labore veniam irure irure ipsum
                        pariatur mollit magna in cupidatat dolore magna irure
                        esse tempor ad mollit. Dolore commodo nulla minim amet
                        ipsum officia consectetur amet ullamco voluptate nisi
                        commodo ea sit eu.
                      </div>
                      <div
                        class="tab-pane fade"
                        id="nav-contact"
                        role="tabpanel"
                        aria-labelledby="nav-contact-tab"
                      >
                        Nulla est ullamco ut irure incididunt nulla Lorem Lorem
                        minim irure officia enim reprehenderit. Magna duis
                        labore cillum sint adipisicing exercitation ipsum.
                        Nostrud ut anim non exercitation velit laboris fugiat
                        cupidatat. Commodo esse dolore fugiat sint velit ullamco
                        magna consequat voluptate minim amet aliquip ipsum aute
                        laboris nisi. Labore labore veniam irure irure ipsum
                        pariatur mollit magna in cupidatat dolore magna irure
                        esse tempor ad mollit. Dolore commodo nulla minim amet
                        ipsum officia consectetur amet ullamco voluptate nisi
                        commodo ea sit eu.
                      </div>
                    </div>
                  </div>
                  <div class="col-md-5 py-10 lg:py-0">
                    <div
                      class="
                        py
                        border-solid border
                        text-2xl
                        font-bold
                        py-3
                        mb-8
                        hover:bg-green-500
                        hover:text-white
                        text-center text-black
                        px-4
                      "
                    >
                      Donate Now
                    </div>
                    <div class="row">
                      <div class="col-md-6 text-white bg-yellow-900 p-3">
                        #FUNDTAG
                      </div>
                      <div class="col-md-6 text-white bg-oonpaysecondary p-3">
                        #Mayjourney21
                      </div>
                    </div>
                    <div class="row space-y-5 lg:space-y-0 px-3 my-5">
                      <div class="col-md-7 border rounded-2xl">
                        <h4 class="text-center">Share Now</h4>
                        <div class="flex justify-between">
                          <span
                            class="iconify"
                            data-icon="teenyicons:facebook-solid"
                            data-inline="false"
                          ></span>
                          <span
                            class="iconify"
                            data-icon="ph:instagram-logo-light"
                            data-inline="false"
                          ></span>
                          <span
                            class="iconify"
                            data-icon="mdi:twitter"
                            data-inline="false"
                          ></span>
                          <span
                            class="iconify"
                            data-icon="mdi:email-alert"
                            data-inline="false"
                          ></span>
                        </div>
                      </div>
                      <div
                        class="
                          col-md-4
                          offset-md-1
                          flex flex-col
                          items-center
                          border
                          justify-center
                          rounded-2xl
                        "
                      >
                        <span
                          class="iconify"
                          data-icon="mdi:content-copy"
                          data-inline="false"
                        ></span>
                        <small class="text-center">Copy & share FUNDTAG</small>
                      </div>
                    </div>
                    <div class="row px-3 my-5">
                      <div class="col-md-12">
                        <h5>
                          <span class="font-bold">N500,000 raised of </span>
                          N1,000,000
                        </h5>
                        <p class="text-green-800 text-2xl font-bold">
                          Lagos Nigeria
                        </p>
                      </div>
                    </div>
                    <div class="progress">
                      <div
                        class="progress-bar bg-success"
                        role="progressbar"
                        style="width: 0%"
                        aria-valuenow="25"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      >
                        0%
                      </div>
                    </div>
                    <small>Funding Goal Reached</small>
                    <div class="flex py-2 justify-between">
                      <p>0 people Supporting</p>
                      <p>60 Days Left</p>
                    </div>

                    <div
                      class="
                        bg-white
                        p-6
                        shadow-lg
                        rounded-lg
                        flex
                        justify-between
                        items-center
                      "
                    >
                      <div class="flex">
                        <div class="mr-4">
                          <img
                            class="
                              shadow
                              sm:w-12
                              sm:h-12
                              w-14
                              h-14
                              rounded-full
                            "
                            src="http://tailwindtemplates.io/wp-content/uploads/2019/03/link.jpg"
                            alt="Avatar"
                          />
                        </div>
                        <div>
                          <h3 class="text-xl font-medium text-gray-700">
                            Fundraiser Info
                          </h3>
                          <p class="text-gray-600">Robert Honda</p>
                        </div>
                      </div>
                      <div>
                        <span class="text-green-400">Verified Account</span>
                        <span
                          class="iconify"
                          data-icon="icon-park-outline:correct"
                          data-inline="false"
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    next() {
      this.$router.push("/share-campaign");
    },
  },
};
</script>

<style  scoped>
.preview {
  cursor: pointer;
}
a:hover {
  text-decoration: none;
}

hr.new4 {
  border: 1px solid #ccc;
}

.btn-primary {
  background: #0c4369;
  border: #0c4369;
}
</style>